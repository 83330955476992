
import { defineComponent } from "vue-demi";
import { COURSES_ENUM } from "@/utils/enums";

export default defineComponent({
  name: "CommonQuestionnaireTemplateTarget",
  props: ["target", "questionnaire"],
  data() {
    return {
      COURSES_ENUM: COURSES_ENUM
    }
  },
  methods: {
    numberWithSpaces(x: number) {
      if (this.questionnaire === COURSES_ENUM.ONE) return x;
      x = Number(x);
      return x.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    parseLinks(text: string) {
      return `<pre style="white-space: pre-wrap; text-align: justify;">${text
        .replace(
          /\b(([\w-]+:\/\/?|www[.])[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|)))/g,
          '<a style="color: rgb(0, 89, 255);" href="$1">$1</a>'
        )
        .replace(/([*].+?[*])/g, "<strong>$1</strong>")
        .replace(/\*/g, "")}</pre>`;
    },
  },
});
