
import { defineComponent } from "vue-demi";
import correctDescription from "@/utils/correctEnd";
import { COURSES_ENUM } from "@/utils/enums";

export default defineComponent({
  name: "CommonQuestionnaireTemplateUsers",
  props: ["expert", "student", "questionnaire"],
  data() {
    return {
      COURSES_ENUM: COURSES_ENUM,
    };
  },
  methods: {
    correctDescription,
  },
});
